import { React } from "react";
import { Card, Col } from "react-bootstrap";

import shortVideo from "../assets/videos/about Zpotter application - short video.mov";
import longVideo from "../assets/videos/about Zpotter application - long video.mov";
import mpfourVideo from "../assets/videos/My Movie.mp4";
import logo from "../assets/aboutImage1.jpg";

import VideoComponent from "../components/VideoComponent";

const VideosScreen = () => {
  return (
    <>
      <Card>
        <Col md={6}>
          <VideoComponent videoSrc={shortVideo} poster={logo} />
        </Col>
      </Card>
      <Card>
        <Col md={9}>
          <VideoComponent videoSrc={longVideo} poster={logo} />
        </Col>
      </Card>
      <Card>
        <Col md={4}>
          <VideoComponent videoSrc={mpfourVideo} poster={logo} />
        </Col>
      </Card>
    </>
  );
};

export default VideosScreen;

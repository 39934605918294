import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const VideoComponent = ({ videoSrc, poster, width = 640, height = 360 }) => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <video
                width={width}
                height={height}
                controls
                poster={poster}
                style={{ width: "100%" }}
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoComponent;

//import React from 'react'
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <ToastContainer position="top-center" autoClose="3000" />
      <Header />
      <main className="py-3">
        <Container style={{ fontSize: "1.1rem", lineHeight: "150%" }}>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default App;

import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import logo from "../assets/logo.png";

const Header = function () {
  return (
    <header style={{ position: "sticky", top: "0px", zIndex: 1 }}>
      <Navbar
        bg="primary"
        variant="dark"
        expand="md"
        collapseOnSelect
        style={{ height: "65px", marginTop: "0.2px" }}
      >
        <Container style={{ marginRight: "8%" }}>
          <LinkContainer to="/">
            <Navbar.Brand>OnesAndZeros Tech</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="ms-auto"
              style={{ backgroundColor: "rgb(60, 76, 93)" }}
            >
              <LinkContainer to="/home">
                <Nav.Link>Home </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About Us </Nav.Link>
              </LinkContainer>{" "}
              <LinkContainer to="/services">
                <Nav.Link>Services </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Contact Us </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/videos">
                <Nav.Link>Videos </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import logo from "../assets/aboutImage1.jpg";
import zpotterVideo from "../assets/videos/My Movie.mp4";
import VideoComponent from "../components/VideoComponent";

const AboutUs = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        {" "}
        <h2>About Us</h2>
        <Col md={6}>
          <p style={{ textIndent: "3rem" }}>
            At OnesAndZeros, we are dedicated to delivering cutting-edge
            software solutions that drive innovation and efficiency for
            businesses of all sizes. With a team of highly skilled developers,
            designers, and project managers, we specialize in creating custom
            software applications, mobile apps, and comprehensive IT services
            tailored to meet the unique needs of our clients. Our mission is to
            empower businesses with technology that enhances productivity,
            streamlines operations, and fosters growth. From initial
            consultation to deployment and ongoing support, we are committed to
            providing exceptional service and building long-term partnerships
            with our clients. Discover how OnesAndZeros can transform your
            business with our expert software solutions.
          </p>
        </Col>
        <Col md={6}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
        <VideoComponent videoSrc={zpotterVideo} poster={logo} />
      </Row>
    </Container>
  );
};

export default AboutUs;

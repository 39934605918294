import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import logo from "../assets/homeImage.jpg";

const HomeScreen = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
        <Col md={6}>
          <h2>Meet the Innovation</h2>
          <p style={{ textIndent: "3rem" }}>
            Welcome to OnesAndZeros Technologies, where we specialize in
            delivering cutting-edge software solutions tailored to meet the
            unique needs of our clients. With a team of dedicated professionals,
            we pride ourselves on our ability to innovate and drive success
            through our comprehensive suite of services. From custom software
            development to robust cybersecurity solutions, our mission is to
            empower businesses to achieve their goals with efficiency and
            confidence.
          </p>
          <p style={{ textIndent: "3rem" }}>
            Our expertise spans a wide range of services, including mobile
            application development, web development, IT consulting, and much
            more. At OnesAndZeros Technologies, we are committed to providing
            exceptional value and support to our clients, helping them navigate
            the complexities of the digital landscape and stay ahead of the
            competition.
          </p>
        </Col>
      </Row>{" "}
      <p>
        Join us on a journey of innovation and excellence, and discover how our
        solutions can transform your business operations and enhance your
        overall performance. Contact us today to learn more about what we can do
        for you.
      </p>
    </Container>
  );
};

export default HomeScreen;

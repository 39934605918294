import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import logo from "../assets/servicesImage2.jpg";

const ServicesScreen = () => {
  const serviceNames = [
    "Mobile Application Development",
    "Website Development",
    "Software Testing and Quality Assurance",
    "Custom Software Development",
    "IT Consulting and Strategy",
    "Software Integration",
    "Cybersecurity Solutions",
    "DevOps and Continuous Integration",
    "UI/UX Design",
    "Maintenance and Support",
    "API Development",
  ];
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        {" "}
        <h2>Our Services</h2>
        <Col md={4}>
          <ul>
            {serviceNames.map((o) => (
              <li style={{ margin: "2%", fontSize: "1.1rem" }} key={o}>
                {o}
              </li>
            ))}
          </ul>
        </Col>
        <Col md={8}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesScreen;

import { React } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../assets/contactImage.png";

const ContactScreen = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        {" "}
        <Col md={8}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
        <Col md={4}>
          <h2>Address</h2>
          No 7, Vivekanandar street, <br></br>Rajakilpakam,<br></br> Chennai -
          600073.
          <br></br>{" "}
          <label
            style={{ fontSize: "1.1rem", fontWeight: "bold", color: "green" }}
          >
            WhatsApp or Call -{"  "}
          </label>
          7806873973 , 9092913756
          <br></br>
          <br></br>
          <h3>Working Hours</h3>
          Monday to Friday
          <br></br>Timing : 10 AM - 6 PM
        </Col>
      </Row>
    </Container>
  );
};

export default ContactScreen;
